@keyframes loader {
  0% {
    transform: rotateZ(0);
  }
  100% {
    transform: rotateZ(360deg);
  }
}

.Button{
  background-color: lightgray; /* Green */
  border-radius: 10px;
  color: black;
  padding: 15px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.Button2{
  background-color: maroon; /* Green */
  border: none;
  color: white;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}

.button2 {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  height: 50px;
  background: maroon;
  border-radius: 10px;
  will-change: transform;
  transition: all 0.2s ease;
  color: white;
  font-size: 16px;
}

.button {
  cursor: pointer;
  width: 80px;
  height: 50px;
  background: maroon;
  border-radius: 10px;
  will-change: transform;
  transition: all 0.2s ease;
  color: white;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button:hover {
  background: #d03d56;
  color: #ffffff;
}

.button.loader {
  width: 40px;  /* Size of the loader */
  height: 40px;
  border-radius: 50%;  /* Circular shape */
  border: 4px solid transparent;  /* Make border thicker */
  border-top: 4px solid black;  /* Black spinner */
  animation: rotate-loader 1s linear infinite;
}

@keyframes rotate-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.button.success {
  background: #3bb873;
  font-size: 14px;
  color: #ffffff;
}

.button.error {
  background: orange;
  border-color: orange;
  font-size: 14px;
  color: #ffffff;
}
